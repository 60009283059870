<template>
    <div style="margin:0 auto">
        <h1 style="margin-top:120px;padding-left:300px" align="left">欢迎进入电动车认证管理系统</h1>

    </div>
</template>

<script>
export default {
    name:'AdminSettings',
    data(){
        return {
            logoninfo: this._self.$root.$store.state.LogonInfo
        }
    }
}
</script>
 